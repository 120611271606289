import React, { useEffect } from "react";
import { List } from "@mui/material";
import DevicelistItem from "./DevicelistItem";

const Devicelist = ({ device, upgradeMachine, role }) => {
  return (
    <List
      sx={{
        width: "100%",
        maxWidth: 360,
        bgcolor: "background.paper",
        padding: 0,
      }}
    >
      <DevicelistItem
        device={device}
        upgradeMachine={upgradeMachine}
        role={role}
      />
    </List>
  );
};

export default Devicelist;
