import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  CircularProgress,
  TextField,
  Autocomplete,
} from "@mui/material";
import EC2Icon from "@mui/icons-material/CloudCircle";
import S3Icon from "@mui/icons-material/CloudUpload";
import LambdaIcon from "@mui/icons-material/Functions";
import IAMIcon from "@mui/icons-material/Lock";
import SGIcon from "@mui/icons-material/Shield";
import PersonIcon from "@mui/icons-material/Person";
import axios from "axios";
import { NGROK } from "../../../APIs";
import EC2Instances from "./components/EC2Instances";
import EC2Details from "./components/EC2Details";
import useUserStore from "../../../services/userStore";
import { myLocalStorage } from "../../../components/StorageHelper";

const awsResources = [
  {
    id: "EC2",
    name: "EC2 Instances",
    icon: <EC2Icon />,
    description: "Virtual servers in the cloud for running applications.",
  },
  {
    id: "S3",
    name: "S3 Buckets",
    icon: <S3Icon />,
    description: "Scalable storage service for storing and retrieving data.",
  },
  {
    id: "Lambda",
    name: "Lambda Functions",
    icon: <LambdaIcon />,
    description:
      "Serverless compute service that runs code in response to events.",
  },
  {
    id: "IAMUsers",
    name: "IAM Users",
    icon: <PersonIcon />,
    description:
      "AWS identities with specific permissions to manage resources.",
  },
  {
    id: "IAMRoles",
    name: "IAM Roles",
    icon: <IAMIcon />,
    description: "Roles that grant specific permissions to AWS services.",
  },
  {
    id: "Security",
    name: "Security Groups",
    icon: <SGIcon />,
    description:
      "Virtual firewalls for controlling inbound and outbound traffic.",
  },
];

const AWSResources = () => {
  const [selectedResource, setSelectedResource] = useState(null);
  const [selectedResourceInstance, setSelectedResourceInstance] =
    useState(null);
  const [groups, setGroups] = useState([]);
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [ec2Datas, setEc2Datas] = useState(null);
  const [s3Datas, setS3Datas] = useState(null);
  const [filteredPolicies, setFilteredPolicies] = useState([]);
  const [selectedDetailsTab, setSelectedDetailsTab] = useState(0);
  const [userWithMatchingPolicies, setUserWithMatchingPolicies] =
    useState(null);
  const [groupsWithMatchingPolicies, setGroupsWithMatchingPolicies] =
    useState(null);
  const [rolesWithMatchingPolicies, setRolesWithMatchingPolicies] =
    useState(null);
  const [isDetailsLoading, setIsDetailsLoading] = useState(false);
  const [selectedTenantName, setSelectedTenantName] = useState(null);
  const userData = useUserStore((state) => state.user);
  const [tenantList, setTenantList] = useState([]);

  const handleTenantChange = (value) => {
    const tenant = tenantList.find((tenant) => tenant.tenantName === value);
    if (tenant !== undefined) {
      setSelectedTenantName(tenant.tenantName);
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const filteredAwsResources = awsResources.filter((resource) =>
    resource.name.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  const handleDetailsTabChange = (event, newValue) => {
    setSelectedDetailsTab(newValue);
  };
  const fetchPolicyDocDetails = async (policyArns) => {
    const data = {
      policyArn: policyArns,
    };

    try {
      const response = await axios.post(
        `${NGROK}/api/aws-policies/by-arn`,
        data,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        },
      );

      return response.data;
    } catch (error) {
      console.error("Error fetching policy details:", error);
      return null;
    }
  };

  const getConsolidatedPolicies = () => {
    const policies = new Set();
    users.forEach((user) => {
      user.AttachedUserPolicies.forEach((policy) => policies.add(policy));
      user.InlineUserPolicies.forEach((policy) => policies.add(policy));
      user.GroupPolicies.forEach((policy) => policies.add(policy));
      user.GroupInlinePolicies.forEach((policy) => policies.add(policy));
    });
    roles.forEach((role) => {
      role.AttachedPermissions.forEach((permission) =>
        policies.add(permission.PolicyArn),
      );
      role.InLinePermissions.forEach((permission) => policies.add(permission));
    });
    groups.forEach((group) => {
      group.AttachedPolicies.forEach((policy) =>
        policies.add(policy.PolicyArn),
      );
      group.InlinePolicies.forEach((policy) => policies.add(policy));
    });
    return Array.from(policies);
  };

  const getAllEntityAllowedforInstance = async (ec2Data) => {
    setIsDetailsLoading(true);
    setSelectedResourceInstance(ec2Data.instanceId);
    const consolidatedPolices = getConsolidatedPolicies();
    const policyData = await fetchPolicyDocDetails(consolidatedPolices);
    const filteredPolicies = policyData.filter((policy) =>
      policy.PolicyDocument.Statement.some(
        (statement) =>
          statement.Resource.includes(ec2Data.instanceArn) ||
          (statement.Action.some((action) => action.startsWith("ec2")) &&
            statement.Resource.includes("*")),
      ),
    );
    setFilteredPolicies(filteredPolicies);
    const policyArns = filteredPolicies.map((policyObj) => policyObj.PolicyArn);

    const usersWithMatchingPolicies = users.filter((user) => {
      const allPolicies = [
        ...user.AttachedUserPolicies,
        ...user.InlineUserPolicies,
        ...user.GroupPolicies,
        ...user.GroupInlinePolicies,
      ];
      return policyArns.some((policy) => allPolicies.includes(policy));
    });

    const groupsWithMatchingPolicies = groups.filter((group) => {
      const allPolicies = [...group.InlinePolicies, ...group.AttachedPolicies];
      return policyArns.some((policy) => allPolicies.includes(policy));
    });

    const rolesWithMatchingPolicies = roles.filter((role) => {
      const allPolicies = [
        ...role.InLinePermissions,
        ...role.AttachedPermissions.map((permission) => permission.PolicyArn),
      ];
      return policyArns.some((policy) => allPolicies.includes(policy));
    });

    rolesWithMatchingPolicies.forEach((role) => {
      users.forEach((user) => {
        if (
          user.Roles.some((userRole) => userRole.RoleName === role.RoleName)
        ) {
          usersWithMatchingPolicies.push(user);
        }
      });
    });

    const uniqueUsers = Array.from(
      new Set(usersWithMatchingPolicies.map((user) => user.UserName)),
    ).map((userName) => {
      return usersWithMatchingPolicies.find(
        (user) => user.UserName === userName,
      );
    });
    const groupMap = new Map(groups.map((group) => [group.GroupId, group]));

    uniqueUsers.forEach((user) => {
      user.Groups.forEach((userGroup) => {
        const group = groupMap.get(userGroup.GroupId);
        if (group) {
          groupsWithMatchingPolicies.push(group);
        }
      });
    });

    const uniqueGroupsWithMatchingPolicies = Array.from(
      new Set(groupsWithMatchingPolicies.map((group) => group.GroupId)),
    ).map((groupId) => {
      return groupsWithMatchingPolicies.find(
        (group) => group.GroupId === groupId,
      );
    });

    setUserWithMatchingPolicies(uniqueUsers);
    setGroupsWithMatchingPolicies(uniqueGroupsWithMatchingPolicies);
    setRolesWithMatchingPolicies(rolesWithMatchingPolicies);
    setIsDetailsLoading(false);
  };

  const fetchAllEC2Data = async () => {
    const params = {
      tenantName: selectedTenantName,
    };
    const response = await axios.get(`${NGROK}/api/aws-ec2`, {
      params: params,
    });
    return response.data;
  };

  const fetchAllS3Data = async () => {
    const params = {
      tenantName: selectedTenantName,
    };
    const response = await axios.get(`${NGROK}/api/aws-s3`, {
      params: params,
    });
    console.log(response.data);
  };

  const fetchInstanceFortheResource = async (resource) => {
    setSelectedResource(resource);
    if (resource.id === "EC2") {
      try {
        const data = await fetchAllEC2Data();
        setEc2Datas(data);
      } catch (err) {
        console.error(err);
      }
    } else if (resource.id === "S3") {
      try {
        const data = await fetchAllS3Data();
        setS3Datas(data);
      } catch (err) {
        console.error(err);
      }
    }
  };
  const getAllUsers = async () => {
    const response = await axios.get(`${NGROK}/api/aws-users`);
    return response.data;
  };

  const getAllGroups = async () => {
    const response = await axios.get(`${NGROK}/api/aws-groups`);
    return response.data;
  };

  const getAllRoles = async () => {
    const response = await axios.get(`${NGROK}/api/aws-roles`);
    return response.data;
  };

  const fetchData = async (initialLoad = false) => {
    try {
      const [groupsData, usersData, roles] = await Promise.all([
        getAllGroups(),
        getAllUsers(),
        getAllRoles(),
      ]);
      setUsers(usersData);
      setGroups(groupsData);
      setRoles(roles);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    fetchData(true);
    const interval = setInterval(fetchData, 10000);
    if (awsResources.length > 0) {
      setSelectedResource(awsResources[0]);
      fetchInstanceFortheResource(awsResources[0]);
    }
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (tenantList.length === 1)
      return setSelectedTenantName(tenantList[0].tenantName);

    if (tenantList?.length > 1) {
      const latestTenantName =
        myLocalStorage.getItem("latestTenant")?.tenantName;

      const tenant = tenantList.find(
        (tenant) => tenant.tenantName === latestTenantName,
      );
      if (tenant) setSelectedTenantName(tenant.tenantName);
      else setSelectedTenantName(tenantList[0].tenantName);
    }
  }, [tenantList]);

  useEffect(() => {
    const fetchTenants = async () => {
      const response = await axios.get(`${NGROK}/api/get-all-domains`);
      setTenantList(response.data);
    };
    fetchTenants();
  }, [userData]);

  return (
    <>
      <Box p={2}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Typography variant="h5">AWS Resources</Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "15px",
            }}
          >
            {tenantList?.length > 0 ? (
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                value={selectedTenantName ? selectedTenantName : " "}
                options={tenantList.map((tenant) => tenant.tenantName)}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Tenant List" />
                )}
                onChange={(e, value) => {
                  handleTenantChange(value);
                }}
              />
            ) : null}
          </Box>
        </Box>
        <Stack direction="row" spacing={4}>
          <Box width="30%">
            <TableContainer component={Paper}>
              <Table
                sx={{
                  height: "fit-content",
                  "& th": {
                    background: "#233044",
                    color: "#fff",
                  },
                  "& td, & th": {
                    border: "1px solid #233044",
                    fontSize: "16px",
                  },
                }}
                size="large"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Resource Details</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={1}>
                      <TextField
                        variant="outlined"
                        placeholder="Search Resources"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        fullWidth
                      />
                    </TableCell>
                  </TableRow>
                  {filteredAwsResources.map((resource) => (
                    <TableRow
                      key={resource.name}
                      selected={resource === selectedResource}
                      onClick={() => fetchInstanceFortheResource(resource)}
                      sx={{
                        "&:hover": {
                          background: "#f5f5f5",
                          cursor: "pointer",
                        },
                        "& td, & th": {
                          border: "1px solid #233044",
                          fontSize: "16px",
                        },
                        "&.Mui-selected": {
                          background: "#233044 !important",
                          "& td, & th": {
                            color: "#fff",
                          },
                        },
                      }}
                    >
                      <TableCell>
                        <Box display="flex" alignItems="center">
                          {resource.icon}
                          <Box ml={2}>
                            <Typography variant="h6">
                              {resource.name}
                            </Typography>
                            <Typography variant="body2">
                              {resource.description}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box width={"30%"}>
            {ec2Datas && selectedResource?.id === "EC2" && (
              <EC2Instances
                ec2Datas={ec2Datas}
                getAllEntityAllowedforInstance={getAllEntityAllowedforInstance}
                selectedResourceInstance={selectedResourceInstance}
              />
            )}
          </Box>
          <Box width={"38%"} sx={{ display: "flex", flexDirection: "column" }}>
            {isDetailsLoading ? (
              <>
                <CircularProgress />
              </>
            ) : (
              ec2Datas &&
              selectedResource?.id === "EC2" &&
              filteredPolicies.length > 0 && (
                <>
                  <EC2Details
                    selectedDetailsTab={selectedDetailsTab}
                    handleDetailsTabChange={handleDetailsTabChange}
                    userWithMatchingPolicies={userWithMatchingPolicies}
                    groupsWithMatchingPolicies={groupsWithMatchingPolicies}
                    rolesWithMatchingPolicies={rolesWithMatchingPolicies}
                  />
                </>
              )
            )}
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default AWSResources;
