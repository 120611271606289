import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import axios from "axios";
import moment from "moment";
import { NGROK } from "../../../APIs";

import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Stack,
  Button,
  Box,
  Autocomplete,
  TextField,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import useGeneralSearch from "../../../hooks/useGeneralSearch";
import SearchField from "./components/SearchField";
import useDateFilter from "../../../hooks/useDateFilter";
import { useLocation } from "react-router-dom";
import { myLocalStorage } from "../../../components/StorageHelper";
import { handleGeneratePDF } from "./components/helpers";
import { DateRangePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import dayjs from "dayjs";

const DevieSpecificLogs = () => {
  const location = useLocation();
  const locationState = location?.state;
  const selectedTenant =
    locationState?.selectedTenant || myLocalStorage.getItem("latestTenant");
  const selectedDevice =
    locationState?.activeComputer || myLocalStorage.getItem("activeComputer");
  const [tableMaxHeight, setTableMaxHeight] = useState("100%");
  const [filterPagination, setFilterPagination] = useState("lw");
  const [customMode, setCustomMode] = useState(false);
  const [startEndDate, setStartEndDate] = useState([dayjs(), dayjs()]);
  const [customStartDate, setCustomStartDate] = useState("");
  const [customEndDate, setCustomEndDate] = useState("");

  const [reachedEnd, setReachedEnd] = useState(false);
  const [auditLogs, setAuditLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingCount, setLoadingCount] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const tableRef = useRef();
  const [pageOffset, setPageOffset] = useState(0);
  const [progress, setProgress] = useState(false);

  const forbiddenSearchFields = ["bucket", "tenantName", "_measurement"];
  const { searchTerm, filteredData, handleSearch } = useGeneralSearch(
    auditLogs,
    null,
    forbiddenSearchFields,
  );

  const {
    dateRange,
    handleChangeDateRange,
    filteredData: logs,
  } = useDateFilter(filteredData, "time");

  const fetchAuditLogs = async () => {
    const bucket = "ondevice-auditlog";
    try {
      let url = `${NGROK}/api/influxdb/influxdblogsdata?bucketName=${bucket}&tenantName=${selectedTenant?.tenantName}&timingMode=${filterPagination}&isDeviceSpecific=true&deviceName=${selectedDevice?.dNSHostName}`;
      if (customMode) {
        url += `&customDateMode=${customMode}&customStartDate=${customStartDate}&customEndDate=${customEndDate}&limit=100`;
      }
      url += `&offset=${pageOffset}`;
      const response = await axios.get(url);
      /* const tenantLogs =
        response?.data?.filter(
          (log) => log.tenantName === selectedTenant?.tenantName
        ) || []; */

      if (response?.data.length > 0) {
        let auditData =
          auditLogs !== null && auditLogs?.length > 0
            ? pageOffset === 0
              ? []
              : auditLogs
            : [];
        let newSet = [...auditData, ...response?.data];

        setAuditLogs(newSet);
      } else {
        setReachedEnd(true);
      }

      setLoading(false);
      setLoadingData(false);
      setProgress(false);
      setLoadingCount(response?.data?.length > 1);
    } catch (error) {
      console.error(error, "error fetchAuditLogs ");
      setLoading(false);
      setProgress(false);
    }
  };

  const filterLogs = (logs, tenantName, deviceName = "") => {
    const filteredLogs = logs?.filter((log) =>
      !deviceName
        ? log.tenantName === tenantName
        : log.tenantName === tenantName && log.computerName === deviceName,
    );

    const getTimestamp = (obj) => obj.timestamp || obj._time;
    const sortByDate = (a, b) => {
      const timestampA = moment(getTimestamp(a)).valueOf();
      const timestampB = moment(getTimestamp(b)).valueOf();
      return timestampB - timestampA;
    };
    return filteredLogs.sort(sortByDate);
  };

  const handleChangeFilterDateRange = (val) => {
    const startEndDate = [dayjs(val[0]), dayjs(val[1])];
    setStartEndDate(startEndDate);
    const startdate = new Date(val[0]);
    const enddate = new Date(val[1]);

    const formattedStartDate = `${startdate.getFullYear()}-${(
      startdate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${startdate.getDate().toString().padStart(2, "0")}`;
    const formattedEndDate = `${enddate.getFullYear()}-${(
      enddate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${enddate.getDate().toString().padStart(2, "0")}`;
    setCustomStartDate(formattedStartDate);
    setCustomEndDate(formattedEndDate);
  };

  const dataPagination = [
    { label: "Last 24 hours", value: "24Hrs" },
    { label: "Last 7 days ", value: "lw" },
    { label: "Last 30 days", value: "lm" },
    { label: "Last 90 days", value: "l3m" },
    { label: "Custom Range", value: "customDate" },
  ];
  const handleFilterPagination = (val) => {
    setProgress(true);
    setPageOffset(0);
    setCustomMode(false);
    setReachedEnd(false);
    setAuditLogs([]);
    setCustomMode(false);
    if (val !== null) {
      let value = dataPagination
        .filter((data) => data.label === val)
        .map((val) => val.value);
      if (value[0] === "customDate") {
        setCustomMode(true);
        const currentDate = new Date();
        const nextWeekDate = new Date(currentDate);
        nextWeekDate.setDate(currentDate.getDate() - 7);
        handleChangeFilterDateRange([nextWeekDate, currentDate]);
      }
      getDatesForFilter(value[0]);
      setFilterPagination(value[0]);
    } else {
      getDatesForFilter("lw");
      setFilterPagination("lw");
    }
  };

  const getDatesForFilter = (mode) => {
    let today = new Date();
    switch (mode) {
      case "lw":
        let last7DaysStart = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() - 7,
        );
        setStartEndDate([dayjs(last7DaysStart), dayjs(today)]);
        break;
      case "lm":
        let last30DaysStart = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() - 30,
        );
        setStartEndDate([dayjs(last30DaysStart), dayjs(today)]);
        break;
      case "l3m":
        let last90DaysStart = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() - 90,
        );
        setStartEndDate([dayjs(last90DaysStart), dayjs(today)]);
        break;
    }
  };

  const loadMore = () => {
    if (!reachedEnd) {
      setLoadingData(true);
      let count = pageOffset;
      setPageOffset(count + 100);
    }
  };
  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = tableRef.current;

    if (scrollTop + clientHeight >= scrollHeight && !loadingData) {
      loadMore();
    }
  };

  const deviceAuditLogs = selectedTenant
    ? filterLogs(logs, selectedTenant.tenantName, selectedDevice?.dNSHostName)
    : [];
  useEffect(() => {
    handleFilterPagination(null);
  }, []);

  useEffect(() => {
    fetchAuditLogs();
  }, [
    selectedTenant?.tenantName,
    filterPagination,
    customEndDate,
    customStartDate,
    customMode,
    pageOffset,
  ]);

  useLayoutEffect(() => {
    if (tableRef.current) {
      const screenHeight = window.innerHeight;
      const rect = tableRef.current.getBoundingClientRect();
      const distanceFromTop = rect.top + window.pageYOffset;
      let maxHeight = screenHeight - (distanceFromTop + 35);
      setTableMaxHeight(maxHeight + "px");
      //tableRef.current.style.maxHeight = `${maxHeight}px`;
      tableRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (tableRef.current) {
        tableRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [tableRef.current]);

  if (loading) return <CircularProgress />;

  return (
    <Stack spacing={4}>
      <Stack direction={"row"}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "15px",
          }}
        >
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={dataPagination.map((value) => value.label)}
            value={
              dataPagination
                .filter((data) => data.value === filterPagination)
                .map((val) => val.label)[0]
            }
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="Time Duration" />
            )}
            onChange={(e, value) => {
              handleFilterPagination(value);
            }}
          />
          {customMode ? (
            <>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateRangePicker
                  startText="Custom start date"
                  endText="Custom end date"
                  maxDate={new Date()}
                  value={startEndDate}
                  onChange={(e) => {
                    handleChangeFilterDateRange(e);
                    handleChangeDateRange(e);
                  }}
                  renderInput={(startProps, endProps) => (
                    <Stack direction={"row"} spacing={3}>
                      <TextField {...startProps} />
                      <TextField {...endProps} />
                    </Stack>
                  )}
                />
              </LocalizationProvider>
            </>
          ) : null}
        </Box>
      </Stack>
      <Stack direction={"row"} spacing={2}>
        {/* <CustomDatePicker
          dateRange={dateRange}
          handleChangeDateRange={handleChangeDateRange}
        /> */}
        <SearchField searchTerm={searchTerm} handleSearch={handleSearch} />
        <Button
          variant="outlined"
          onClick={() =>
            handleGeneratePDF(startEndDate, deviceAuditLogs, searchTerm)
          }
          disabled={deviceAuditLogs.length === 0}
        >
          Download Report
        </Button>
      </Stack>
      {progress ? (
        <CircularProgress />
      ) : deviceAuditLogs.length ? (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer
            ref={tableRef}
            sx={{
              maxHeight: tableMaxHeight,
            }}
          >
            <Table
              stickyHeader
              size="small"
              aria-label="a dense table"
              sx={{
                "& td, & th": {
                  border: "1px solid #233044",
                },
              }}
            >
              <TableHead
                sx={{
                  backgroundColor: "#233044",
                }}
              >
                <TableRow>
                  <TableCell
                    sx={{
                      color: "white",
                      fontSize: "16px",
                      backgroundColor: "#233044",
                    }}
                    align="center"
                  >
                    Time
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "white",
                      fontSize: "16px",
                      backgroundColor: "#233044",
                    }}
                    align="center"
                  >
                    Computer User
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "white",
                      fontSize: "16px",
                      backgroundColor: "#233044",
                    }}
                    align="center"
                  >
                    Computer Name
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "white",
                      fontSize: "16px",
                      backgroundColor: "#233044",
                    }}
                    align="center"
                  >
                    Type
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "white",
                      fontSize: "16px",
                      backgroundColor: "#233044",
                    }}
                    align="center"
                  >
                    Resource Name
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "white",
                      fontSize: "16px",
                      backgroundColor: "#233044",
                    }}
                    align="center"
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {deviceAuditLogs.map((log, index) => (
                  <TableRow
                    key={`${log.timestamp}${log.message}${log._time}${index}`}
                  >
                    <TableCell sx={{ fontSize: "16px" }} align="center">
                      {moment(
                        log.timestamp !== undefined ? log.timestamp : log._time,
                      ).format("DD.MM.YYYY HH:mm:ss")}
                    </TableCell>
                    <TableCell sx={{ fontSize: "16px" }} align="center">
                      {log.computerUserEmail}
                    </TableCell>
                    <TableCell sx={{ fontSize: "16px" }} align="center">
                      {log.computerName}
                    </TableCell>
                    <TableCell sx={{ fontSize: "16px" }} align="center">
                      {log.resourceType}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        maxWidth: "300px",
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                      }}
                      align="center"
                    >
                      {log.resourceName}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        maxWidth: "600px",
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                      }}
                      align="center"
                    >
                      {log.message}
                    </TableCell>
                  </TableRow>
                ))}
                {loadingData && !reachedEnd && (
                  <TableRow
                    sx={{
                      border: 1,
                    }}
                  >
                    <TableCell colSpan={7} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ) : selectedTenant ? (
        <Typography fontSize={16} p={5}>
          There are no logs related to selected Tenant or Device
        </Typography>
      ) : (
        <Typography fontSize={16} p={5}>
          Select Tenant
        </Typography>
      )}
    </Stack>
  );
};

export default DevieSpecificLogs;
